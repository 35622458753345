import { TQuestion } from '@/src/types/quiz';

export const getScreeningPage = (questions: TQuestion[]) => (
  questions.length > 0 ? questions[0].id : undefined
);

export const trackAndGoTo = (url: string, eventName: string) => {
  window.analytics.track(eventName);
  window.location.href = url;
};
