/* eslint-disable max-len */
import { MARKET_FR, MARKET_SE, MARKET_US } from '@consts/market';
import { TAdjust, TMarkets } from '@/src/types/common';

const getQueryString = (param: string) => {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(param);
};

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};

export const isPartnerToken = () => Boolean(getQueryString('t'));

export const getToken = (market: TMarkets) => {
  const partnerToken = getQueryString('t');
  let defaultToken = '';

  switch (market) {
    case MARKET_US:
      defaultToken = process.env.GATSBY_DEFAULT_PARTNER_TOKEN_US as string;
      break;
    case MARKET_FR:
      defaultToken = process.env.GATSBY_DEFAULT_PARTNER_TOKEN_FR as string;
      break;
    case MARKET_SE:
    default:
      defaultToken = process.env.GATSBY_DEFAULT_PARTNER_TOKEN_SE as string;
  }

  return partnerToken || defaultToken;
};

export const getAffiliate = (market: TMarkets) => {
  try {
    return parseJwt(getToken(market));
  } catch {
    return {};
  }
};

export const prepareDownloadUrl = (
  url: string,
  market: TMarkets,
  buttonText: string,
  adjustUrlMapping: TAdjust = {},
) => {
  let adjustQuery = [
    `campagin=${getAffiliate(market)?.affiliate_name?.split(' ').join('-').toLowerCase()}`,
    'adgroup=screening',
    `creative=${buttonText.split(' ').join('-').toLowerCase()}`,
  ];

  // Overwrite default query if utm tags is found
  if (adjustUrlMapping?.campaign || adjustUrlMapping?.adgroup || adjustUrlMapping?.creative) {
    adjustQuery = [];
    if (adjustUrlMapping?.campaign) {
      adjustQuery.push(`campaign=${adjustUrlMapping.campaign}`);
    }
    if (adjustUrlMapping?.adgroup) {
      adjustQuery.push(`adgroup=${adjustUrlMapping.adgroup}`);
    }
    if (adjustUrlMapping?.creative) {
      adjustQuery.push(`creative=${adjustUrlMapping.creative}`);
    }
  }

  const urlStart = url.includes('?') ? '&' : '?';

  return url + urlStart + adjustQuery.join('&');
};
