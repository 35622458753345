import React, { useEffect } from 'react';

type TSmsTag = {
  dataToken?: string,
  tagHeadline?: string,
  placeholder?: string,
};

const SmsTag = ({ dataToken = '', tagHeadline = '', placeholder = '' }: TSmsTag) => {
  useEffect(() => {
    const scriptTag = document.createElement('script');

    scriptTag.src = 'https://assets.jointacademy.com/framework/sms/v4.0.0/sms.js?v=aosiii2dfh';
    scriptTag.async = false;
    scriptTag.defer = true;

    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  return (
    <div style={{ maxWidth: '560px', margin: '0 auto' }}>
      {tagHeadline && <p className="margin-bottom--xxs text--bold text--sm">{tagHeadline}</p>}
      <div className="jaSmsFormWrapper" data-placeholder={placeholder} data-token={dataToken} />
    </div>
  );
};

export default SmsTag;
