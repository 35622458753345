import React from 'react';
import Layout from '@components/common/Layout';
import Seo from '@components/common/Seo';
import { JOINT_OTHER } from '@consts/variant';
import PageOther from '@components/pageOther/PageOther';
import { MARKET_SE } from '@consts/market';

const IndexPage = () => (
  <Layout
    language="sv"
    segmentId={process.env.GATSBY_SEGMENT_SCREENING_2_PRODUCTION_SE as string}
    page={JOINT_OTHER}
  >
    <Seo
      title="Screening - Annan ledsmärta"
      description="Screening - Annan ledsmärta"
      lang="sv"
    />

    <PageOther
      market={MARKET_SE}
      buttonDownloadUrl="https://app.adjust.com/x207bmg"
    />
  </Layout>
);

export default IndexPage;
